import httpUtil from "@/utils/httpUtil";

/**获取共享空间列表 */
export const getShareSpaceList = async params => httpUtil.post("/api/crmPc/shareSpace/getShareSpaceList", params)

/**设置共享空间 */
export const setShareSpace = async params => httpUtil.post("/api/crmPc/shareSpace/setShareSpace", params)

/**获得分享空间时间详情 */
export const getShareSpaceTime = async params => httpUtil.post("/api/crmPc/shareSpace/getShareSpaceTime", params)

/**获得分享空间时间列表 */
export const getShareSpaceTimeList = async params => httpUtil.post("/api/crmPc/shareSpace/getShareSpaceTimeList", params)
<template>
    <div class="view pa24 spaces">
        <div class="d-flex">
            <div>
                <el-input class="w200 mb10 mr10" placeholder="请输入场地关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button class="ma" type="primary" @click="showAddModel">新增场地</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
            <template v-slot:table>
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="coverPhoto" align="center" label="封面图片" width="120px">
                    <template slot-scope="scope">
                        <el-image v-if="scope.row.coverPhoto" style="width: 50px; height: 50px;margin-bottom:-10px"
                            :src="scope.row.coverPhoto">
                        </el-image>
                        <p v-else
                            style="width: 50px; height: 50px;line-height: 50px;font-size:12px;margin:0 auto;background: #eee;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="spacePhoto" align="center" label="环境图片" width="120px">
                    <template slot-scope="scope">
                        <div v-if="scope.row.spacePhoto">
                            <el-image style="width: 50px; height: 50px;margin-bottom:-10px"
                                :src="scope.row.spacePhoto.split(',')[0]"
                                :preview-src-list="scope.row.spacePhoto.split(',')">
                            </el-image> ×{{ scope.row.spacePhoto.split(',').length }}
                        </div>
                        <p v-else
                            style="width: 50px; height: 50px;line-height: 50px;font-size:12px;margin:0 auto;background: #eee;">
                            暂无图片
                        </p>
                    </template>
                </el-table-column>
                <el-table-column prop="spaceName" align="center" label="场地名称" show-overflow-tooltip />
                <el-table-column prop="linkMan" align="center" label="联系人" show-overflow-tooltip />
                <el-table-column prop="linkPhone" align="center" label="联系电话" show-overflow-tooltip />
                <el-table-column prop="spaceAddress" align="center" label="场地地址" show-overflow-tooltip />
                <el-table-column prop="minNum" align="center" label="限制人数" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.minNum + " ~ " + scope.row.maxNum }}人
                    </template>
                </el-table-column>
                <el-table-column prop="timeNum" align="center" label="共享天数" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.timeNum }}天
                    </template>
                </el-table-column>
                <el-table-column prop="timeNum" align="center" label="场地大小" show-overflow-tooltip>
                    <template slot-scope="scope">
                        {{ scope.row.spaceArea }}㎡
                    </template>
                </el-table-column>
                <el-table-column prop="spaceFee" width="120" align="center" label="场地费用" show-overflow-tooltip>
                    <template slot-scope="scope">
                        <div v-if="scope.row.spaceFee">{{ scope.row.spaceFee }}元/小时</div>
                        <div v-else>免费</div>
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="80">
                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>

        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle" :visible.sync="showModel"
            :destroy-on-close="true" width="1100px" center @close="closeFun">
            <el-form :model="formData" :rules="rules" ref="formData" label-width="120px" v-loading="detailsLoading">
                <el-form-item label="封面图片" prop="coverPhoto" required>
                    <el-upload class="carouselUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
                        :class="{ disabled: uploadCoverPhotoDisabled }" :limit="1" :on-progress="linkmanImgProgress"
                        :on-success="coverPhotoLinkmanImgSuccess" :on-remove="coverPhotoLinkmanImgRemove"
                        :file-list="coverPhoto">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="d-flex w100p align-items-center">
                        <p>最多只能上传1张，建议上传150x100，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-form-item label="环境图片" prop="spacePhoto" required>
                    <el-upload class="carouselUpload" :action="$store.state.uploadingUrl" multiple list-type="picture-card"
                        :class="{ disabled: uploadSpacePhotoDisabled }" :limit="9" :on-progress="linkmanImgProgress"
                        :on-success="spacePhotoLinkmanImgSuccess" :on-remove="spacePhotoLinkmanImgRemove"
                        :file-list="showSpacePhoto">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                    <div class="d-flex w100p align-items-center">
                        <p>最多只能上传9张，建议上传150x100，格式可为png、jpg或jpeg</p>
                    </div>
                </el-form-item>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="场地名称" prop="spaceName">
                            <el-input class="w100p" v-model="formData.spaceName" placeholder="请输入场地名称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="联系人" prop="linkMan">
                            <el-input class="w100p" v-model="formData.linkMan" placeholder="请输入联系人"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="联系电话" prop="linkPhone">
                            <el-input class="w100p" v-model="formData.linkPhone" placeholder="请输入联系电话"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="场地费用" prop="spaceFee">
                            <el-input type="number" class="w100p suffixInput" v-model="formData.spaceFee"
                                placeholder="请输入场地费用">
                                <template slot="append">元/小时</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="赞助费用" prop="supportPrice">
                            <el-input type="number" class="w100p suffixInput" v-model="formData.supportPrice"
                                placeholder="请输入赞助费用">
                                <template slot="append">元</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="限制人数" required>
                            <el-col :span="11">
                                <el-form-item prop="minNum">
                                    <el-input class="w100p" v-model="formData.minNum" type="number"
                                        placeholder="最少要求人数"></el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :span="2" class="textc">~</el-col>
                            <el-col :span="11">
                                <el-form-item prop="maxNum">
                                    <el-input class="w100p" v-model="formData.maxNum" type="number"
                                        placeholder="最多可容纳人数"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="场地面积" prop="spaceArea">
                            <el-input type="number" class="w100p suffixInput" v-model="formData.spaceArea"
                                placeholder="请输入场地面积">
                                <template slot="append">㎡</template>
                            </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="场地地址" prop="spaceAddress">
                    <el-input class="w100p" v-model="formData.spaceAddress" placeholder="请输入场地地址"></el-input>
                </el-form-item>
                <el-row>
                    <el-form-item label="坐标" required>
                        <div class="d-flex">
                            <el-form-item prop="lng">
                                <el-input class="w130 mr10" type="number" placeholder="地理经度" v-model="formData.lng"
                                    clearable />
                            </el-form-item>
                            <el-form-item prop="lat">
                                <el-input class="w130 mr10" type="number" placeholder="地理纬度" v-model="formData.lat"
                                    clearable />
                            </el-form-item>
                            <el-button type="primary" style="height:32px" icon="el-icon-location-outline"
                                @click="showMapFun"></el-button>
                        </div>
                    </el-form-item>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="可适用活动标签" prop="activityType">
                            <el-select class="w100p" v-model="formData.activityType" multiple placeholder="请选择活动标签">
                                <el-option v-for="(row, index) in tagData" :key="index" :label="row.name"
                                    :value="row.labelId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :md="12">
                        <el-form-item label="配套资源" prop="resources">
                            <el-input class="w100p" v-model="formData.resources" placeholder="请输入配套资源"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :md="12">
                        <el-form-item label="参会人群要求" prop="resources">
                            <el-input class="w100p" v-model="formData.joinRule" placeholder="请输入参会人群要求"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-collapse v-model="collapseValue" v-if="timeList.length > 0">
                    <el-collapse-item title="设置共享时间" name="1">
                        <div>
                            <div class="d-flex">
                                <div style="width:calc(100% - 450px)">
                                    <el-button-group>
                                        <el-button @click="openingHours(180)">开放最近半年</el-button>
                                        <el-button @click="openingHours(90)">开放最近三个月</el-button>
                                        <el-button @click="openingHours(30)">开放最近一个月</el-button>
                                        <el-button @click="openingHours(7)">开放最近一周</el-button>
                                    </el-button-group>
                                    <div class="e6a23c mt10">快速添加日期会把配置好的时间取消</div>
                                    <el-calendar v-model="calendarValue" ref="calendar" class="myCalendar">
                                        <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
                                        <template slot="dateCell" slot-scope="{date, data}">
                                            <p :class="getCheckedDate(data.day) ? 'is-selected' : ''">
                                                {{ data.day.split('-').slice(1).join('-') }} {{
                                                    getCheckedDate(data.day) ? '✔️' :
                                                    ''
                                                }}
                                            </p>
                                        </template>
                                    </el-calendar>
                                </div>
                                <div style="width:450px">
                                    <div>
                                        <p class="fs8 mt10"><span class="mr10">选择的开放日期</span><el-button type="danger"
                                                @click="emptyDate()">重置</el-button></p>
                                        <div class="e6a23c mb10">已预约的日期无法进行删除</div>
                                        <div class="checkedDate">
                                            <el-tag v-for="(date, index) in checkedDate" :key="index"
                                                :type="checkedIdnex === index ? '' : getDayType(date)"
                                                @click="checkedDateFun(index)" @close="delDate(index)"
                                                :closable="checkedDate.length > 1 && checkedDateStatus[index] == 'allowDel'"
                                                size="medium">
                                                {{ date | getDay }} {{ date }}
                                            </el-tag>
                                        </div>
                                    </div>
                                    <div>
                                        <p class="fs8 mt20">
                                            <span class="mr10">设置开放时间</span>
                                            <el-checkbox v-model="timeAll" size="medium"
                                                @change="changeTimeAll">全天开放</el-checkbox>
                                            <el-checkbox v-model="repeatTime" @change="changeRepeatTime" size="medium"
                                                title="当前设置的开放时间应用到每一天"><i class="el-icon-warning"></i> 重复</el-checkbox>
                                        </p>
                                        <div class="e6a23c">一旦选择 "重复" 将无法恢复原来的时间设置</div>
                                        <div class="e6a23c">已预约的时间无法进行修改</div>
                                        <div class="flex-wrap-y flex-a-b-c ml10 timeList">
                                            <el-checkbox v-model="timeList[checkedIdnex].eight"
                                                @change="changeTime('eight')" size="medium"
                                                :disabled="timeList[checkedIdnex].eight > 0" :true-label="0"
                                                :false-label="-1" border>08:00-09:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].nine" @change="changeTime('nine')"
                                                size="medium" :disabled="timeList[checkedIdnex].nine > 0" :true-label="0"
                                                :false-label="-1" border>10:00-11:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].ten" @change="changeTime('ten')"
                                                size="medium" :disabled="timeList[checkedIdnex].ten > 0" :true-label="0"
                                                :false-label="-1" border>11:00-12:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].twelve"
                                                @change="changeTime('twelve')" size="medium"
                                                :disabled="timeList[checkedIdnex].twelve > 0" :true-label="0"
                                                :false-label="-1" border>12:00-13:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].thirteen"
                                                @change="changeTime('thirteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].thirteen > 0" :true-label="0"
                                                :false-label="-1" border>13:00-14:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].fourteen"
                                                @change="changeTime('fourteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].fourteen > 0" :true-label="0"
                                                :false-label="-1" border>14:00-15:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].fifteen"
                                                @change="changeTime('fifteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].fifteen > 0" :true-label="0"
                                                :false-label="-1" border>15:00-16:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].sixteen"
                                                @change="changeTime('sixteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].sixteen > 0" :true-label="0"
                                                :false-label="-1" border>16:00-17:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].seventeen"
                                                @change="changeTime('seventeen')" size="medium"
                                                :disabled="timeList[checkedIdnex].seventeen > 0" :true-label="0"
                                                :false-label="-1" border>17:00-18:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].eighteen"
                                                @change="changeTime('eighteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].eighteen > 0" :true-label="0"
                                                :false-label="-1" border>18:00-19:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].nineteen"
                                                @change="changeTime('nineteen')" size="medium"
                                                :disabled="timeList[checkedIdnex].nineteen > 0" :true-label="0"
                                                :false-label="-1" border>19:00-20:00</el-checkbox>
                                            <el-checkbox v-model="timeList[checkedIdnex].twenty"
                                                @change="changeTime('twenty')" size="medium"
                                                :disabled="timeList[checkedIdnex].twenty > 0" :true-label="0"
                                                :false-label="-1" border>20:00-21:00</el-checkbox>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-collapse-item>
                </el-collapse>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="submitData" :loading="loadingBtn">提 交</el-button>
            </span>
        </el-dialog>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" title="选择地址" :visible.sync="showMap"
            width="80%" :destroy-on-close="true">
            <map-model @lngLatChange="lngLatChange" :lng="formData.lng" :lat="formData.lat"></map-model>
            <!-- <div id="baidumap" style="width: 100%; height: 400px; margin-top: 10px"></div> -->
            <span slot="footer" class="dialog-footer">
                <el-button @click="showMap = false" type="primary">确 定</el-button>
            </span>
        </el-dialog>
        <!-- <Calendar :date="new Date()" @enter="mouseenter" @leave="mouseleave" @changeMonth="changeMonth"
            style="width:400px;height:400px">
            可使用动态插槽名设置，v-slot:[slotName]
            <template v-slot:2021-5-21>
                <div style="position:absolute;bottom:-20px">今天</div>
            </template>
        </Calendar> -->
    </div>
</template>

<script>
// import Calendar from '@/components/common/calendar.vue'
import commonTable from "@/components/common/commonTable";
import { getDataTimeSec, moveToErr } from "@/utils";
import { getShareSpaceList, setShareSpace, getShareSpaceTimeList } from "@/api/spaces";
import { getActivityEventLabel } from "@/api/companyManage.js";
import mapModel from "@/components/common/mapModel";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        mapModel,
        // Calendar
    },
    data() {
        return {
            //列表配置
            loading: false, //表格加载
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            contentType: {},
            total: 0,
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            //编辑新增配置
            modelTitle: "",//模态框标题
            showModel: false,//模态框显示状态
            coverPhoto: [],//封面图
            showSpacePhoto: [],//环境图片
            spacePhoto: [],
            detailsLoading: false,
            formData: {
                coverPhoto: "",//封面图片
                spacePhoto: "",//环境图片
                minNum: '',//最低人数要求
                maxNum: '',//可容纳人数
                spaceName: "",//场地名称
                linkMan: "",//联系人
                linkPhone: "",//联系电话
                spaceFee: "",//场地费用
                supportPrice: "",//赞助费用
                spaceArea: "",//场地大小
                spaceAddress: "",//场地地址
                lat: 0,//纬度
                lng: 0,//经度
                activityType: [],//可适用活动类型
                resources: "",//配套资源
                joinRule: "",//参会人群要求
            },
            rules: {
                coverPhoto: {
                    required: true,
                    message: "请上传封面图片",
                },
                spacePhoto: {
                    required: true,
                    message: "请上传环境图片",
                },
                spaceName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入场地名称",
                },
                lng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
                lat: [{ required: true, message: '请选择纬度', trigger: 'blur' }],
                linkMan: {
                    required: true,
                    trigger: "blur",
                    message: "请输入联系人",
                },
                linkPhone: [{
                    required: true,
                    trigger: "blur",
                    message: "请输入联系电话",
                }, { message: "手机号格式错误", pattern: this.$store.state.phoneReg }
                ],
                spaceFee: {
                    required: true,
                    trigger: "blur",
                    message: "请输入场地费用",
                },
                supportPrice: {
                    required: true,
                    trigger: "blur",
                    message: "请输入赞助费用",
                },
                minNum: {
                    required: true,
                    trigger: "blur",
                    message: "最少要求人数",
                },
                maxNum: {
                    required: true,
                    trigger: "blur",
                    message: "最多可容纳人数",
                },
                spaceArea: {
                    required: true,
                    trigger: "blur",
                    message: "请输入场地面积",
                },
                spaceAddress: {
                    required: true,
                    trigger: "blur",
                    message: "请输入场地地址",
                },
            },
            formType: "",
            updateId: 0,
            statusData: { 0: "正常", 1: "禁用" },
            loadingBtn: false,
            tagData: [],
            //配置开放时间
            calendarValue: "",//日期值
            collapseValue: "1",
            checkedIdnex: 0,//时间列表的下标
            timeRange: '',//时间范围类型
            timeList: [],//开放时间列表
            timeKey: [
                "eight",//8:00-9:00
                "nine",//9:00-10:00
                "ten",//10:00-11:00
                "eleven",//11:00-12:00
                "twelve",//12:00-13:00
                "thirteen",//13:00-14:00
                "fourteen",//14:00-15:00
                "fifteen",//15:00-16:00
                "sixteen",//16:00-17:00
                "seventeen",//17:00-18:00
                "eighteen",//18:00-19:00
                "nineteen",//19:00-20:00
                "twenty",//20:00-21:00
            ],
            timeAll: false,//是否全天
            repeatTime: false,//是否重复时间设置
            checkedDate: [],//选中的年月日
            checkedDateStatus: [],//allowDel prohibitDel
            //地图设置
            showMap: false,
            txMap: {},
            txMapSearch: {},
            txMapSuggest: {},
            checkedAll: false,
            delDateData: [],
        };
    },
    computed: {
        //检查时间是否已占用
        checkTimeOccupied() {
            return (val) => {
                return val !== 0 && val !== -1 ? true : false;
            }
        },
        uploadSpacePhotoDisabled() {
            return this.spacePhoto && this.spacePhoto.length >= 9;
        },
        uploadCoverPhotoDisabled() {
            return this.formData.coverPhoto;
        },
        getCheckedDate() {
            return val => {
                return this.checkedDate.indexOf(val) != -1 ? true : false;
            }
        },
        getDayType() {
            return date => {
                let day = new Date(date).getDay();
                return day === 6 || day === 0 ? 'warning' : 'info';
            }
        },
    },
    watch: {
        //选中日期
        calendarValue(val) {
            let ymd = getDataTimeSec(val, "yy-mm-dd");
            if (this.checkedDate.indexOf(ymd) == -1) {//判断是否已有这个日期
                this.checkedDate.push(ymd);
                this.checkedDateStatus.push('allowDel')
                let timeObj = { date: ymd };
                for (let key of this.timeKey) {
                    if (this.repeatTime) {
                        timeObj[key] = this.checkTimeOccupied(this.timeList[this.checkedIdnex][key]) ? -1 : this.timeList[this.checkedIdnex][key];
                    } else {
                        timeObj[key] = -1;
                    }
                }
                this.timeList.push(timeObj);

            }
            this.checkedIdnex = this.checkedDate.indexOf(ymd);
            if (Object.values(this.timeList[this.checkedIdnex]).indexOf(-1) == -1) {//判断是否全天
                this.timeAll = true;
            } else {
                this.timeAll = false;
            }
        },
        "formData.lng": function (val) {
            this.formData.lng = Number(val);
        },
        "formData.lat": function (val) {
            this.formData.lat = Number(val);
        }
    },
    filters: {
        getDay(date) {
            let dateData = ['周日', '周一', '周二', '周三', '周四', '周五', '周六'];
            let day = new Date(date).getDay();
            return dateData[day]
        },
    },
    created() {
        this.getActivityEventLabel();
        this.getList();
    },
    methods: {
        // mouseenter() { },
        // mouseleave() { },
        // changeMonth() { },
        /**@method 显示地图 */
        showMapFun() {
            this.showMap = true;
        },
        lngLatChange(lnglat) {
            //-console.log(lnglat);
            this.formData.lng = lnglat.lng;
            this.formData.lat = lnglat.lat;
        },
        /**@method 选中日期 */
        checkedDateFun(i) {
            this.checkedIdnex = i;
            if (Object.values(this.timeList[this.checkedIdnex]).indexOf(-1) == -1) {//判断是否全天
                this.timeAll = true;
            } else {
                this.timeAll = false;
            }
        },
        /**@method 重复设置开放时间设置 */
        changeRepeatTime(val) {
            if (val) {
                for (let index in this.timeList) {
                    //当前值是已占用的 则不修改
                    for (let key of this.timeKey) {
                        if (!this.checkTimeOccupied(this.timeList[index][key])) {
                            let currentValue = this.checkTimeOccupied(this.timeList[this.checkedIdnex][key]) ? this.changeTimeAll ? 0 : -1 : this.timeList[this.checkedIdnex][key];//当前要重复的值
                            this.timeList[index][key] = currentValue;
                        }
                    }
                }
            }
        },
        /**@method 开放最近天数 */
        openingHours(day) {
            let oneDay = 24 * 60 * 60 * 1000;//一天
            let checkedDate = [].concat(this.checkedDate);
            this.emptyDate();
            this.timeAll = false;
            this.repeatTime = false;
            this.delDateData = [];
            for (let i = 1; i < day; i++) {//循环天数添加时间数据
                let date = getDataTimeSec(new Date().getTime() + ((i - 1) * oneDay), 'yy-mm-dd');//获取日期
                this.delDateData.push(date)
                if (this.checkedDate.indexOf(date) == -1) {
                    this.checkedDate.push(date);
                    this.checkedDateStatus.push('allowDel')
                    let timeObj = { date: date };
                    for (let key of this.timeKey) {
                        timeObj[key] = -1;
                    }
                    this.timeList.push(timeObj)
                }
            }
            this.checkedIdnex = 0;
            for (let cDate of checkedDate) {
                if (this.delDateData.indexOf(cDate) == -1 && this.checkedDate.indexOf(cDate) == -1) {
                    this.delDateData.push(cDate);
                }
            }
        },
        /**@method 新增 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增共享空间"
            this.formType = "add";
            this.updateId = 0;
            this.timeAll = false;
            this.repeatTime = false;
            this.initDateData();
        },
        /**@method 编辑 */
        showUpdateModel(row) {
            this.showModel = true;
            this.detailsLoading = true;
            this.modelTitle = "编辑共享空间"
            this.formType = "update";
            this.updateId = row.spaceId;
            this.timeAll = false;
            this.repeatTime = false;
            this.formData = {
                coverPhoto: row.coverPhoto,//封面图片
                spacePhoto: row.spacePhoto,//环境图片
                minNum: row.minNum,//最低人数要求
                maxNum: row.maxNum,//可容纳人数
                spaceName: row.spaceName,//场地名称
                linkMan: row.linkMan,//联系人
                linkPhone: row.linkPhone,//联系电话
                spaceFee: row.spaceFee,//场地费用
                supportPrice: row.supportPrice,//赞助费用
                spaceArea: row.spaceArea,//场地大小
                spaceAddress: row.spaceAddress,//场地地址
                lat: row.lat,//纬度
                lng: row.lng,//经度
                activityType: row.activityType ? row.activityType.split(",").map(Number) : [],//可适用活动类型
                resources: row.resources,//配套资源
                joinRule: row.joinRule,//参会人群要求
            };
            if (row.coverPhoto) {
                this.coverPhoto = [{ url: row.coverPhoto }]
            }
            if (row.spacePhoto) {
                for (let url of row.spacePhoto.split(",")) {
                    this.showSpacePhoto.push({ url: url });
                    this.spacePhoto.push(url);
                }
            }
            getShareSpaceTimeList({
                spaceId: row.spaceId,
                isDown: false,
                fromNow: 1
            }).then(res => {
                this.detailsLoading = false;
                if (res.data) {
                    this.checkedIdnex = 0;
                    this.timeList = res.data;
                    this.checkedDate = [];
                    this.checkedDateStatus = [];
                    if (Object.values(this.timeList[0]).indexOf(-1) == -1) {
                        this.timeAll = true;
                    } else {
                        this.timeAll = false;
                    }
                    for (let row of res.data) {
                        this.checkedDate.push(row.date);
                        let timeObj = { ...row };
                        delete timeObj.date;
                        delete timeObj.createTime;
                        delete timeObj.updateTime;
                        delete timeObj.spaceId;
                        delete timeObj.timeId;
                        let checkedDateStatus = "";
                        for (let key in timeObj) {
                            if (timeObj[key] != 0 && timeObj[key] != -1) {
                                checkedDateStatus = 'prohibitDel';
                                break;
                            } else {
                                checkedDateStatus = 'allowDel';
                            }
                        }
                        this.checkedDateStatus.push(checkedDateStatus)
                    }
                }
            }).catch(err => {
                this.detailsLoading = false;
            })
        },
        /**@method 提交数据 */
        submitData() {
            this.formData.spacePhoto = this.spacePhoto.join(",");
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    let timeList = [];
                    for (let row of this.timeList) {//区分开已占用的时间不传
                        let isOpen = false;
                        let timeObj = {};
                        for (let key in row) {
                            if (row[key] === 0 || row[key] === -1 || key === 'date') {//只传时间、值等于0、-1的数据
                                isOpen = true;
                                timeObj[key] = row[key];
                            }
                        }
                        if (isOpen) {
                            timeList.push(timeObj);
                        }
                    }
                    let parmas = {
                        ...this.formData,
                        timeList: timeList,
                        activityType: this.formData.activityType.join(","),
                    }
                    let message = "新增成功"
                    if (this.formType === 'update') {
                        let delDate = [];
                        for (let dDate of this.delDateData) {//修改时要删除的日期
                            if (this.checkedDate.indexOf(dDate) == -1) {
                                delDate.push(dDate)
                            }
                        }
                        parmas.spaceId = this.updateId
                        parmas.delDate = delDate;
                        message = "编辑成功";
                    }
                    this.loadingBtn = true;
                    setShareSpace(parmas).then(res => {
                        this.loadingBtn = false;
                        if (res.code === 200) {
                            this.$message({
                                message: message,
                                type: "success"
                            })
                            this.showModel = false;
                            this.getList();
                        } else {
                            this.$message.error(err.message)
                        }
                    }).catch(err => {
                        this.loadingBtn = false;
                        if (err.code === 201) {
                            this.$message.error(err.message)
                        }
                    })
                } else {
                    moveToErr(this);
                }
            });
        },
        /**@method 清空日期 */
        emptyDate() {
            let checkedDate = [];
            let checkedDateStatus = [];
            let timeList = [];
            for (let index in this.checkedDateStatus) {
                if (this.checkedDateStatus[index] == "prohibitDel") {//判断不能删除的数据
                    checkedDate.push(this.checkedDate[index]);
                    checkedDateStatus.push(this.checkedDateStatus[index]);
                    timeList.push(this.timeList[index]);
                } else {
                    this.delDateData.push(this.checkedDate[index]);
                }
            }
            //判断是否有不能删的值
            //没有就初始化时间数据
            //有就添加不能删除的数据
            if (timeList.length === 0) {
                this.initDateData();
            } else {
                this.checkedIdnex = 0;
                this.checkedDate = checkedDate;
                this.checkedDateStatus = checkedDateStatus;
                this.timeList = timeList;
            }
        },
        /**@method 删除日期 */
        delDate(index) {
            if (this.checkedIdnex + 1 === this.checkedDate.length) {
                this.checkedIdnex = this.checkedIdnex - 1;
            }
            if (this.delDateData.indexOf(this.checkedDate[index]) == -1) {
                this.delDateData.push(this.checkedDate[index])
            }
            this.$delete(this.checkedDate, index);
            this.$delete(this.checkedDateStatus, index);
            this.$delete(this.timeList, index);
        },
        /**@method 监听选中时间 */
        changeTime(key) {
            //判断是否选中全天
            if (Object.values(this.timeList[this.checkedIdnex]).indexOf(-1) == -1) {
                this.timeAll = true;
            } else {
                this.timeAll = false;
            }
            //选中重复的时候 修改数据中属性一样的值
            let value = this.timeList[this.checkedIdnex][key];
            if (this.repeatTime) {
                for (let index in this.timeList) {
                    this.timeList[index][key] = this.checkTimeOccupied(this.timeList[index][key]) ? this.timeList[index][key] : value;
                }
            }
        },
        /**@method 监听选择全天 */
        changeTimeAll(val) {
            //判断是否重复
            //选中重复修改全部数据
            //未选中修改单条
            if (this.repeatTime) {
                for (let index in this.timeList) {
                    for (let key of this.timeKey) {
                        if (val) {
                            this.timeList[index][key] = this.checkTimeOccupied(this.timeList[index][key]) ? this.timeList[index][key] : 0;
                        } else {
                            this.timeList[index][key] = this.checkTimeOccupied(this.timeList[index][key]) ? this.timeList[index][key] : -1;
                        }
                    }
                }
            } else {
                for (let key of this.timeKey) {
                    if (val) {
                        this.timeList[this.checkedIdnex][key] = this.checkTimeOccupied(this.timeList[this.checkedIdnex][key]) ? this.timeList[this.checkedIdnex][key] : 0;
                    } else {
                        this.timeList[this.checkedIdnex][key] = this.checkTimeOccupied(this.timeList[this.checkedIdnex][key]) ? this.timeList[this.checkedIdnex][key] : -1;
                    }
                }
            }
        },
        /**@method 初始化开放时间设置 */
        initDateData() {
            let ymd = getDataTimeSec(new Date(), "yy-mm-dd");
            this.checkedIdnex = 0;
            this.checkedDate = [ymd];
            this.checkedDateStatus = ['allowDel']
            this.timeAll = false;
            this.repeatTime = false;
            this.timeList = [{
                date: ymd,
                eight: -1,//8:00-9:00
                nine: -1,//9:00-10:00
                ten: -1,//10:00-11:00
                eleven: -1,//11:00-12:00
                twelve: -1,//12:00-13:00
                thirteen: -1,//13:00-14:00
                fourteen: -1,//14:00-15:00
                fifteen: -1,//15:00-16:00
                sixteen: -1,//16:00-17:00
                seventeen: -1,//17:00-18:00
                eighteen: -1,//18:00-19:00
                nineteen: -1,//19:00-20:00
                twenty: -1,//20:00-21:00
            }];
        },
        closeFun() {
            this.formData = {
                coverPhoto: "",//封面图片
                spacePhoto: "",//环境图片
                minNum: 1,//最低人数要求
                maxNum: 1,//可容纳人数
                spaceName: "",//场地名称
                linkMan: "",//联系人
                linkPhone: "",//联系电话
                spaceFee: "",//场地费用
                supportPrice: "",//赞助费用
                spaceArea: "",//场地大小
                spaceAddress: "",//场地地址
                lat: 0,//纬度
                lng: 0,//经度
                activityType: [],//可适用活动类型
                resources: "",//配套资源
                joinRule: "",//参会人群要求

                startDate: "",
                endDate: "",
            };
            this.coverPhoto = [];
            this.showSpacePhoto = [];
            this.spacePhoto = [];
            this.initDateData();
        },
        /**@method 图片上传 */
        linkmanImgProgress(event, file, fileList) {

        },
        /**@method 删除图片 */
        coverPhotoLinkmanImgRemove(file, fileList) {
            //-console.log(file, fileList);
            this.formData.coverPhoto = "";
            this.coverPhoto = [];
        },
        /**@method 上传回调 */
        coverPhotoLinkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.formData.coverPhoto = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
        },
        /**@method 删除图片 */
        spacePhotoLinkmanImgRemove(file, fileList) {
            for (let row of fileList) {
                if (row.response) {
                    this.spacePhoto.push(row.response.data);
                } else {
                    this.spacePhoto.push(row.url);
                }
            }
        },
        /**@method 上传回调 */
        spacePhotoLinkmanImgSuccess(response, file, fileList) {
            this.$message.closeAll();
            this.$message({
                message: "上传成功",
                type: "success",
            });
            this.spacePhoto.push(`https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`);
        },
        /**@method 搜索 */
        searchFun() { this.currentPage = 1; this.getList() },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                spaceName: this.searchData.name
            };
            try {
                this.loading = true;
                let res = await getShareSpaceList(params)
                this.loading = false;

                const { data } = res;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
            }
        },
        /**@method 获取活动标签 */
        getActivityEventLabel() {
            getActivityEventLabel({ type: 1 }).then(res => {
                if (res.code === 200) {
                    this.tagData = res.data;
                }
            })
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
    },
};
</script>
<style>
.myCalendar .el-calendar-table td.is-selected {
    background: transparent !important;
}

.spaces .is-selected {
    color: #1989FA;
}

.timeList .el-checkbox {
    margin: 20px 0 0 0 !important;
}

.checkedDate .el-tag {
    margin-left: 15px;
    margin-bottom: 15px;
    cursor: pointer;
    width: 131px;
}

.spaces .el-collapse {
    border: 1px solid #eee;
    padding: 0 10px;
    border-radius: 5px;
}
</style>
<style lang="scss" scoped>
.checkedDate {
    height: 313px;
    overflow-y: scroll;
    padding: 10px 0;
    border-radius: 5px;
    border: 1px solid #eee;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}

.disabled .el-upload--picture-card {
    display: none;
}
</style>